<template>
  <div class="backdrop" v-if="deductionsStore.loading">
    <div class="image-container d-flex justify-content-center align-items-center">
      <div class="loader"></div>
      <img src="@/assets/cs-loader.png" alt="Loading image">
    </div>
  </div>
  <MDBTabs v-model="adjustmentTab">
    <MDBTabNav tabsClasses="mb-3">
      <MDBTabItem class="fw-bold px-2 px-md-4" tabId="defaultAdjustments" href="defaultAdjustments"
        v-if="userDetail.userTypeId == 1 || userDetail.userTypeId == 4">
        Default Adjustment
      </MDBTabItem>
      <MDBTabItem class="fw-bold px-2 px-md-4" tabId="assetRevenueAdjustments" href="assetRevenueAdjustments">Asset
        Revenue
        Adjustment</MDBTabItem>
    </MDBTabNav>
    <MDBTabContent>
      <MDBTabPane tabId="defaultAdjustments" v-if="userDetail.userTypeId == 1 || userDetail.userTypeId == 4">
        <div class="d-flex flex-column gap-2">
          <MDBRow v-if="userDetail.userTypeId == 1 || userDetail.userTypeId == 4">
            <MDBCol>
              <MDBBtn class="fw-bold" size="sm" color="primary"
                @click="handleModalState('addDefaultDeduction')">
                <MDBIcon iconStyle="fas" icon="plus" class="me-1" />
                Add Default Adjustment
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow class="justify-content-center">
            <div class="d-flex flex-column">
              <DefaultAdjustmentsTable />
            </div>
          </MDBRow>
        </div>
      </MDBTabPane>
      <MDBTabPane tabId="assetRevenueAdjustments">
        <div class="gap-3 d-flex flex-column">
          <div class="gap-2 px-0 d-md-flex d-block justify-content-between align-items-center">
            <div v-if="userDetail.userTypeId == 1 || userDetail.userTypeId == 4"
              class="d-md-flex d-block gap-2 justify-content-start mb-md-0 mb-4">
              <MDBBtn class="fw-bold mb-2 mb-md-0" size="sm" color="primary" @click="handleModalState('addDeduction')"
                :disabled="disableAddDeductions">
                <MDBIcon iconStyle="fas" icon="plus" class="me-1" />
                Add Adjustment
              </MDBBtn>
              <MDBBtn class="fw-bold" size="sm" color="primary" @click="handleModalState('assignDefaultDeduction')">
                <MDBIcon iconStyle="fas" icon="plus" class="me-1" />
                Assign Default Adjustments
              </MDBBtn>
            </div>
            <div class="d-flex flex-wrap gap-2 justify-content-start justify-content-md-end">
              <MDBSelect v-model:options="monthsOption" v-model:selected="deductionsStore.deductionForm.month"
                label="Month" />
              <MDBSelect v-model:options="yearsOption" v-model:selected="deductionsStore.deductionForm.year"
                label="Year" />
              <MDBBtn class="fw-bold text-nowrap" color="primary" @click="handleDeductionSearch">
                Search
              </MDBBtn>
            </div>
          </div>
          <MDBRow class="justify-content-center">
            <div class="d-flex flex-column">
              <DeductionsTable />
            </div>
          </MDBRow>
        </div>
      </MDBTabPane>
    </MDBTabContent>
  </MDBTabs>
  <DeductionsModal />
  <DeductionsToast />
</template>

<script setup>
import { MDBRow, MDBCol, MDBSelect, MDBBtn, MDBTabs, MDBTabNav, MDBTabContent, MDBTabItem, MDBTabPane, MDBIcon } from "mdb-vue-ui-kit";
import DeductionsTable from "./DeductionsTable.vue";
import DeductionsModal from "./DeductionsModal.vue";
import { useDeductionsStore } from "../../store/deductionsStore";
import { useRoute } from "vue-router";
import { onMounted, ref, watch } from "@vue/runtime-core";
import DeductionsToast from "./DeductionsToast.vue";
import DefaultAdjustmentsTable from "./DefaultAdjustmentsTable.vue";
import { useUserDetailStore } from "../../store/userDetailStore";
import { storeToRefs } from "pinia";

onMounted(() => {
  if (userDetailStore.userDetail.userTypeId != 1) {
    adjustmentTab.value = "assetRevenueAdjustments";
  }
});

const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);
const route = useRoute();
const userId = route.params.id;
const deductionsStore = useDeductionsStore();
deductionsStore.deductionForm.userId = userId;
deductionsStore.defaultDeductionForm.userId = userId;
const adjustmentTab = ref("defaultAdjustments");

const monthsOption = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

const yearsOption = [];
const createYearsOption = () => {
  for (let year = 2010; year <= 2100; year++) {
    yearsOption.push({
      text: year,
      value: year,
    });
  }
  return yearsOption;
};

createYearsOption();
const handleDeductionSearch = () => {
  deductionsStore.getDeductions({
    UserId: deductionsStore.deductionForm.userId,
    Month: deductionsStore.deductionForm.month,
    Year: deductionsStore.deductionForm.year,
  });
};

const handleModalState = (modalType) => {
  if (modalType == "addDeduction") {
    deductionsStore.deductionsModalType = "";
    deductionsStore.deductionsModalType = "addDeduction";
    deductionsStore.deductionsModalState = true;
  } else if (modalType == "assignDefaultDeduction") {
    deductionsStore.deductionsModalType = "";
    deductionsStore.deductionsModalType = "assignDefaultDeduction";
    deductionsStore.getDefaultDeductions({
      UserId: deductionsStore.deductionForm.userId,
    });
    deductionsStore.deductionsModalState = true;
  } else if (modalType == "addDefaultDeduction") {
    deductionsStore.deductionsModalType = "";
    deductionsStore.deductionsModalType = "addDefaultDeduction";
    deductionsStore.deductionsModalState = true;
  }
};

let disableAddDeductions = ref(true);
watch(
  () => deductionsStore.deductions.data,
  (newDeductionsData) => {
    if (newDeductionsData !== null) {
      disableAddDeductions.value = false;
    }
  }
);
</script>

<style lang="scss" scoped>
.btn-primary {
  background-color: var(--primary);
}

:deep(.nav-tabs) {
  border-color: var(--accent);
}
</style>

<style>
.adjustment-loader {
  color: var(--accent);
}

.nav-tabs .nav-link {
  color: var(--accent);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--primary);
  border-color: var(--primary);
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgb(153, 153, 153);
  border-radius: 50%;
  animation: BorderSpin 1s linear infinite;
}

.image-container img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@keyframes BorderSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
