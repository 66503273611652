<template>
	<Paginate :page-count="deductionsPaginationStore.pagination.totalPages"
		:click-handler="deductionsPaginationStore.pagination.currentPage" :prev-text="'Previous'" :next-text="'Next'"
		:container-class="'pagination'" first-last-button class="overflow-auto m-0"></Paginate>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import { useDeductionsPaginationStore } from "../../store/deductionsPaginationStore";
export default {
	components: {
		Paginate,
	},
	setup() {
		const deductionsPaginationStore = useDeductionsPaginationStore();

		return {
			deductionsPaginationStore,
		};
	},
};
</script>
