<template>
	<div class="overflow-auto">
		<MDBTable hover class="align-middle">
			<thead class="table-light">
				<tr>
					<th class="sticky-top" v-for="(item, i) in tableHeaders" :key="i" scope="col">
						{{ item.header }}
					</th>
				</tr>
			</thead>
			<tbody v-if="deductionsStore.deductions.data === null">
				<tr class="text-center">
					<td colspan="3">
						Select a month and a year.
					</td>
				</tr>
			</tbody>
			<tbody v-else-if="deductionsStore.deductions.data.length < 1">
				<tr class="text-center">
					<td colspan="3">
						No adjustments available.
					</td>
				</tr>
			</tbody>
			<tbody v-else-if="deductionsStore.deductions.data.length > 0">
				<tr v-for="(item, i) in deductionsStore.deductions.data" :key="i">
					<td>{{ item.deductionName }}</td>
					<td>{{ item.fee }}</td>
					<td v-if="userDetail.userTypeId == 1 || userDetail.userTypeId == 4">
						<button class="message-btn btn ms-2 btn-outline-primary btn-floating btn-sm"
							@click.stop="clickAction(item, 'edit')">
							<em class="fa fa-pen"></em>
						</button>
						<button class="trash-btn btn ms-2 btn-outline-primary btn-floating btn-sm"
							@click.stop="clickAction(item, 'delete')">
							<em class="fa fa-trash" />
						</button>
					</td>
				</tr>
			</tbody>
		</MDBTable>
	</div>
	<div class="position-absolute bottom-0 start-0 overflow-auto p-3">
		<DeductionsPagination />
	</div>
</template>

<script>
import { MDBTable } from "mdb-vue-ui-kit";
import { computed } from "vue";
import { PatchDeductions } from "../../services/Deductions/PatchDeductions";
import { useDeductionsStore } from "../../store/deductionsStore";
import DeductionsPagination from "./DeductionsPagination.vue";
import { useUserDetailStore } from "../../store/userDetailStore";
import { storeToRefs } from "pinia";

export default {
	components: {
		MDBTable,
		DeductionsPagination,
	},
	setup() {
		const userDetailStore = useUserDetailStore();
		const { userDetail } = storeToRefs(userDetailStore);
		const deductionsStore = useDeductionsStore();
		const tableHeaders = computed(() => {
			if (userDetail.value.userTypeId == 1 || userDetail.value.userTypeId == 4) {
				return [
					{
						header: "Adjustment",
					},
					{
						header: "Fee",
					},
					{
						header: "Actions",
					},
				];
			} else {
				return [
					{
						header: "Adjustment",
					},
					{
						header: "Fee",
					},
				];
			}
		});
		const clickAction = (item, type) => {
			if (type === "delete") {
				deleteUser(item);
			} else if (type === "edit") {
				editUser(item);
			}
		};
		const deleteUser = (item) => {
			PatchDeductions(item.id, { IsDeleted: true }).then(
				setTimeout(() => {
					deductionsStore.getDeductions({
						UserId: deductionsStore.deductionForm.userId,
						Month: deductionsStore.deductionForm.month,
						Year: deductionsStore.deductionForm.year,
					});
				}, 500)
			);
			deductionsStore.deductionForm = { ...deductionsStore.deductionForm, ...item };
		};
		const editUser = (item) => {
			deductionsStore.deductionsModalType = "editDeduction";
			deductionsStore.deductionsModalState = true;
			deductionsStore.deductionForm = { ...deductionsStore.deductionForm, ...item };
		};
		return {
			tableHeaders,
			clickAction,
			deductionsStore,
			userDetail,
		};
	},
};
</script>

<style scoped>
.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

th {
	text-transform: uppercase;
}

tr {
	cursor: default;
}
</style>
